export const seo = {
  url: 'ecommerce',
  title: {
    en: 'E-commerce | Delivery service for online stores',
    es: 'E-commerce | Servicio de entrega para tiendas online',
    ro: 'Comerț electronic | Serviciu de livrare pentru magazine online',
  },
  desc: {
    en: 'Automation of orders and shipment delivery. Combine e-commerce with the best transport and e-logistics solution available.',
    es: 'Automatización de pedidos y entrega de paquetería. Combine el comercio electrónico con la mejor solución de transporte y logística electrónica disponible.',
    ro: 'Automatizarea comenzilor și livrarea expedierilor. Combină comerțul electronic cu cea mai bună soluție de transport și logistică electronică disponibilă.',
  },
  keywords: [
    'ecommerce',
    'e-logistics',
    'e-commerce',
    'online shopping',
    'warehousing',
  ],
}

export const intro = {
  title: {
    en: 'E-commerce',
    es: 'E-commerce',
    ro: 'E-commerce',
  },
  desc: {
    en: 'We provide comprehensive services in e-commerce logistics, using innovative IT solutions, enabling automation of the ordering processes and efficient delivery of shipments.',
    es: 'Brindamos servicios integrales en el área de logística de comercio electrónico, utilizando soluciones informáticas innovadoras que permiten la automatización de pedidos y la entrega eficiente de paquetes.',
    ro: 'Oferim servicii complete în logistica comerțului electronic, folosind soluții IT inovatoare, permițând automatizarea proceselor de comandă și livrarea eficientă a transporturilor.',
  },
  button: {
    text: {
      en: 'Quick Start',
      es: 'Inicio rápido',
      ro: 'Pornire rapidă',
    },
    link: '/ecommerce/#form',
  },
}

export const main = {
  title: {
    en: 'E-commerce - complex logistics solutions for online stores',
    es: 'Comercio electrónico: soluciones logísticas integrales para su tienda en línea',
    ro: 'E-commerce - soluții logistice complexe pentru magazine online',
  },
  texts: [
    {
      en: `<span>We created <strong>OMIDA e-commerce</strong>, to facilitate the purchasing process and connect your e-commerce with the best available solution to handle transportation and e - logistics</span>`,
      es: `<span> Creamos <strong> OMIDA e-commerce </strong> para mejorar el proceso de compra y combinar tu e-commerce con la mejor solución disponible en servicios de transporte
      y e - logística </span>`,
      ro: `<span>Am creat <strong>OMIDA e-commerce</strong>, pentru a facilita procesul de cumpărare și pentru a vă conecta comerțul electronic cu cea mai bună soluție disponibilă pentru a gestiona transportul și logistica electronică</span>`,
    },
    {
      en: `<span>We provide support for all activities related to the <strong>receipt of the goods in the warehouse, inventory management, picking and delivery of goods, as well as B2C and B2B distribution</strong>, including the deliveries across Europe</span>`,
      es: '<span> Garantizamos el manejo de todas las actividades relacionadas con la <strong>recepción de mercancías en almacén, la gestión de stocks así como la finalización y liberación de mercancías, así como la distribución</strong> en el modelo B2C y B2B, teniendo en cuenta entregas a cuenta en toda Europa </span>',
      ro: '<span>Oferim suport pentru toate activitățile legate de <strong>primirea mărfurilor în depozit, gestionarea stocurilor, ridicarea și livrarea mărfurilor, precum și distribuția B2C și B2B</strong>, inclusiv livrările în toată Europa</span>',
    },
    {
      en: `<span>Thanks to utilisation of our logistic infrastructure and support of experienced project- and operational teams, our customers subcontracting the entire logistics process are <strong>able to save their time and money!</strong> Being a leader in the transport industry, we guarantee the highest level of cooperation!</span>`,
      es: `<span> El uso de nuestra infraestructura logística y <strong> el apoyo de un equipo experimentado </strong> diseño y operación <strong> permite a nuestros clientes que nos confían todo el proceso logístico ¡ahorrar tiempo y dinero! </strong> ¡Como líder en la industria del transporte, garantizamos la cooperación al más alto nivel!</span>`,
      ro: '<span>Datorită utilizării infrastructurii noastre logistice și sprijinului echipelor de proiecte și operaționale cu experiență, clienții noștri care subcontractează întregul proces logistic sunt <strong>capabili să-și economisească timp și bani!</strong> Fiind un lider în industria transporturilor , garantăm cel mai înalt nivel de cooperare!</span>',
    },
  ],
  features: [
    {
      icon: 'boxes',
      subtitle: {
        en: 'Professional ',
        es: 'Profesional ',
        ro: 'Profesional',
      },
      title: {
        en: 'Warehousing',
        es: 'Almacenamiento',
        ro: 'Magazinaj',
      },
      desc: {
        en: 'Professional warehousing and value-added services, such as: preparation of sets, technical and quality controls and repackaging',
        es: 'Almacenamiento profesional y servicios de valor añadido como preparación de kits, control técnico y de calidad y reenvasado',
        ro: 'Servicii profesionale de depozitare și valoare adăugată, cum ar fi: pregătirea seturilor, controale tehnice și de calitate și reambalare',
      },
    },
    {
      icon: 'truck',
      subtitle: {
        en: 'Lower costs of ',
        es: 'Menores costos ',
        ro: 'Costuri mai mici ale',
      },
      title: {
        en: 'Logistics',
        es: 'Logísticos',
        ro: 'Logistică',
      },
      desc: {
        en: 'Low inventory levels and fewer late shipments thanks to appropriate management',
        es: 'Bajo inventario y menos envíos atrasados ​​gracias a una gestión adecuada',
        ro: 'Niveluri scăzute ale stocurilor și mai puține livrări cu întârziere datorită gestionării adecvate',
      },
    },
    {
      icon: 'document',
      subtitle: {
        en: 'Handling returns ',
        es: 'Manejo de devoluciones ',
        ro: 'Gestionarea retururilor',
      },
      title: {
        en: 'Reporting',
        es: 'Informes',
        ro: 'Raportare',
      },
      desc: {
        en: 'Full and made to measure handling returns with a report on each event',
        es: 'Gestión completa y personalizada de las devoluciones con un informe de cada evento',
        ro: 'Gestionarea returnărilor complete și pe măsură cu un raport pentru fiecare eveniment',
      },
    },
    {
      icon: 'time',
      subtitle: {
        en: 'Real time ',
        es: 'Datos en tiempo ',
        ro: 'Timp real',
      },
      title: {
        en: 'Data',
        es: 'Real',
        ro: 'Date',
      },
      desc: {
        en: 'Inventory control and the opportunity of making the right decisions already at the stage of ordering',
        es: 'Control de inventario y la capacidad de tomar las decisiones correctas en la etapa de pedido',
        ro: 'Controlul stocurilor și posibilitatea de a lua deciziile corecte deja în faza de comandă',
      },
    },
    {
      icon: 'dollar',
      subtitle: {
        en: 'Lower costs of ',
        es: 'Menor costo de  ',
        ro: 'Costuri mai mici ale',
      },
      title: {
        en: 'Distribution',
        es: 'Distribución',
        ro: 'Distributie',
      },
      desc: {
        en: 'We concentrate your shipping volume, which will significantly reduce your delivery costs',
        es: 'Concentramos su volumen de envío, lo que le permitirá reducir significativamente los costos de envío',
        ro: 'Vă concentrăm volumul de expediere, ceea ce vă va reduce semnificativ costurile de livrare',
      },
    },
    {
      icon: 'unload',
      subtitle: {
        en: 'Integration with',
        es: 'Integración con empresas de ',
        ro: 'Integrarea cu',
      },
      title: {
        en: 'Courier companies',
        es: 'Mensajería',
        ro: 'Firme de curierat',
      },
      desc: {
        en: 'We provide integration with courier companies, post and parcel machines. Competitive prices, thanks to the cooperation with a large operator',
        es: 'Brindamos integración con empresas de mensajería, máquinas postales y de paquetería. Precios competitivos gracias a la cooperación con un gran operador',
        ro: 'Oferim integrare cu companii de curierat, mașini de poștă și coletărie. Prețuri competitive, datorită cooperării cu un mare operator',
      },
    },
    {
      icon: 'settings',
      subtitle: {
        en: 'Customized',
        es: 'Oferta',
        ro: 'Personalizat',
      },
      title: {
        en: 'Personalization',
        es: 'Personalizada',
        ro: 'Personalizare',
      },
      desc: {
        en: 'We offer personalization on request (e.g. assembling sets, attaching inserts, packaging customization, and even laser engraving)',
        es: 'Ofrecemos personalización a pedido (incluidos juegos de plegado, insertos adjuntos, personalización de empaques e incluso grabado láser)',
        ro: 'Oferim personalizare la cerere (de exemplu, seturi de asamblare, inserții de atașare, personalizare ambalaj și chiar gravare cu laser)',
      },
    },
    {
      icon: 'server',
      subtitle: {
        en: 'System',
        es: 'Sistema',
        ro: 'Sistem',
      },
      title: {
        en: 'e-WMS',
        es: 'e-WMS',
        ro: 'e-WMS',
      },
      desc: {
        en: 'We are using computer software, which streamlines the inventory management system',
        es: 'Trabajamos en un software informático que mejora el sistema de gestión de almacenes',
        ro: 'Folosim software de calculator, care eficientizează sistemul de management al stocurilor',
      },
    },
  ],
}

export const video = {
  title: {
    en: 'That is 11 years of experience in the transportation market!',
    es: '¡Son 11 años de experiencia en el mercado del transporte!',
    ro: 'Adică 11 ani de experiență pe piața transporturilor!',
  },
  desc: {
    en: 'We provide maximum benefits and support for your e-Business! 5.5 thousand m2 of space and 12 meters high... Be sure to see our class A warehouse!',
    es: '¡Ofrecemos los máximos beneficios y soporte para su comercio electrónico! 5.5 mil m2 de espacio y 12 metros de altura... ¡Vea nuestro almacén de clase A!',
    ro: 'Oferim beneficii maxime și suport pentru e-Business! 5,5 mii m2 de spațiu și 12 metri înălțime... Nu uitați să vedeți depozitul nostru de clasa A!',
  },
  url: 'https://www.youtube.com/watch?v=aK4zOafFehE',
  more: [
    'https://www.youtube.com/watch?v=KnvUzmNN9LA',
    'https://www.youtube.com/watch?v=t0rlX8OHBE0',
    'https://www.youtube.com/watch?v=P5oXMbLAeq0',
    'https://www.youtube.com/watch?v=EqdIrxWnBRA',
  ],
}

export const stages = {
  id: 'stages',
  title: {
    en: 'Learn more about Omida e-commerce service processes',
    es: 'Conozca los procesos del servicio de comercio electrónico de Omida',
    ro: 'Aflați mai multe despre procesele de servicii de comerț electronic Omida',
  },
  desc: {
    en: 'See for yourself what can add value to your e-Business',
    es: 'Vea lo que puede agregar valor a su e-Business',
    ro: 'Vedeți singur ce poate adăuga valoare e-Business-ului dvs',
  },
  stage: {
    en: 'Stage',
    es: 'Escenario',
    ro: 'Etapă',
  },
  stages: [
    {
      number: '01',
      title: {
        en: 'Receipt and verification',
        es: 'Aceptación y verificación',
        ro: 'Primire și verificare',
      },
      body: {
        en: '<ul><li>We receive and verify goods from production plants or from our clients contractors</li>  <li>We provide a safe receipt of goods under the supervision of CCTV cameras</li>  <li>We guarantee the receipt of goods within the framework of the strategy individually determined with our customers, i.e. the receipt as well as qualitative and quantitative control on the level of an item, carton or pallet</li>   <li>We report each time on irregularities in the respective quantities of the goods and their quality</li>   </ul> ',
        es: '<ul> <li> Aceptamos y verificamos mercancías de plantas de producción o de contratistas de nuestros clientes </li> <li> Aseguramos la recepción segura de mercancías bajo la supervisión de cámaras de circuito cerrado de televisión </li> <li> Garantizamos la recepción de mercancías como parte de una estrategia individual acordada con nuestros Clientes, es decir, aceptación y control de calidad y cantidad desde el nivel de artículos, cartones o palés.</li> <li> Informamos de cualquier irregularidad en las cantidades de mercancías y su calidad </li> </ul>',
        ro: '<ul><li>Primim și verificăm bunuri de la făbricile de producție sau de la clienții noștri contractori</li> <li>Oferim o primire sigură a mărfurilor sub supravegherea camerelor CCTV</li> <li>Garantăm primirea de mărfuri în cadrul strategiei stabilite individual cu clienții noștri, adică a recepției, precum și a controlului calitativ și cantitativ la nivelul unui articol, carton sau palet</li> <li>Raportăm de fiecare dată neregulile din respectivul cantitățile de mărfuri și calitatea acestora</li> </ul>',
      },
    },
    {
      number: '02',
      title: {
        en: 'Warehousing',
        es: 'Almacenamiento',
        ro: 'Magazinaj',
      },
      body: {
        en: '<ul>  <li>We provide you with a class A warehouse and storage racks for pallets weighing up to 800 kg</li> <li>Separate areas with access control for high-value products</li> <li>We guarantee year-round temperatures above 16°C. and humidity at the level of 50-70%</li> <li>We offer CCTV monitoring with up to 30-days long availability of stored data and 24-hours supervision of a licensed security company</li> <li>We provide direct online access to stock levels</li> </ul>',
        es: '<ul> <li> Ponemos a tu disposición un almacén clase A y racks para el almacenaje de tarimas de hasta 800 Kg. </li> <li> Separamos zonas con control de acceso para productos de alto valor </li> <li> garantizar una temperatura durante todo el año superior a 16°C. y humedad al nivel de 50 - 70% </li> <li> Ofrecemos monitoreo con la disponibilidad de registro de datos por hasta 30 días y supervisión las 24 horas de una empresa de seguridad autorizada </li> <li> Brindamos acceso directo en línea a los niveles de existencias </ li> </ul>',
        ro: '<ul> <li>Vă punem la dispoziție un depozit de clasa A și rafturi de depozitare pentru paleți cu o greutate de până la 800 kg</li> <li>Zone separate cu control acces pentru produse de mare valoare</li> <li>Garantăm temperaturi pe tot parcursul anului peste 16°C. și umiditate la nivelul de 50-70%</li> <li>Oferim monitorizare CCTV cu disponibilitate de până la 30 de zile a datelor stocate și supraveghere 24 de ore din 24 de ore a unei companii de securitate licențiate</li> <li>Noi oferiți acces online direct la nivelurile stocurilor</li> </ul>',
      },
    },
    {
      number: '03',
      title: {
        en: 'Picking orders',
        es: 'Preparación de pedidos',
        ro: 'Preluarea comenzilor',
      },
      body: {
        en: '<ul> <li>We offer a wide range of co-packing services (assembling sets, attaching leaflets, samples, gadgets, and other inserts, as well as the use of eco-friendly packing fillers and dedicated packages)</li> <li>We also provide preparation of the shipping documents, printing and attaching necessary documents to the shipped packages</li> </ul>',
        es: '<ul> <li> Ofrecemos una amplia gama de servicios de co-packing (juegos plegados, pegado de folletos, muestras, gadgets y otros insertos, así como el uso de rellenos ecológicos y embalajes dedicados) </li> <li> también preparar documentos de envío, impresiones y agregar los documentos necesarios a los envíos </li> </ul>',
        ro: '<ul> <li>Oferim o gamă largă de servicii de co-ambalare (seturi de asamblare, atașare pliante, mostre, gadgeturi și alte inserții, precum și utilizarea de umpluturi ecologice și pachete dedicate)</li> <li>De asemenea, asigurăm pregătirea documentelor de expediere, tipărirea și atașarea documentelor necesare la pachetele expediate</li> </ul>',
      },
    },
    {
      number: '04',
      title: {
        en: 'Deliveries',
        es: 'Entrega',
        ro: 'Livrări',
      },
      body: {
        en: '<ul> <li>We offer a full package of courier services - national shipping by DPD, FedEx, TNT, DHL, UPS, parcel machines and pallet transport</li>  <li>We ensure customer comfort through the possibility of choosing: the method, place and time of delivery optimal for the recipient</li><li>We guarantee the proper selection of 3 types of packaging sizes for parcel machines</li>  <li>We monitor deliveries on a regular basis</li></ul>',
        es: '<ul> <li> Ofrecemos un paquete completo de servicios de mensajería: envíos de mensajería nacionales DPD, Fedex, TNT, DHL UPS, casilleros para paquetes y transporte de paletas </li> <li> Brindamos comodidad al cliente gracias a la elección de: método, lugar y tiempo de entrega óptimo para el destinatario </li> <li> Garantizamos la selección adecuada de embalaje para 3 tipos de máquinas de paquetería </li> <li> Supervisamos las entregas de forma continua </li> < /ul>',
        ro: '<ul> <li>Oferim un pachet complet de servicii de curierat - expediere națională prin DPD, FedEx, TNT, DHL, UPS, mașini de colete și transport paleți</li> <li>Asigurăm confortul clienților prin posibilitatea de a alege: metoda, locul și timpul de livrare optime pentru destinatar</li><li>Garantăm selecția corectă a 3 tipuri de dimensiuni de ambalaj pentru mașinile de colectat</li> <li>Monitorizăm livrările în mod regulat</li ></ul>',
      },
    },
    {
      number: '05',
      title: {
        en: 'Handling returns and complaints',
        es: 'Manejo de devoluciones y reclamos',
        ro: 'Gestionarea retururilor și reclamațiilor',
      },
      body: {
        en: '<ul> <li>We handle return orders for e-commerce as well as for the traditional channel</li>  <li>On the logistics side, by returns handling we mean verifying the returned goods and subjecting them to quality control</li> <li>On customers request, we can carry out the logistics of return (e.g. repairs, warranty inspections, disinfection and ironing)</li> </ul>',
        es: '<ul> <li> Gestionamos las devoluciones para e-commerce y para el canal tradicional </li> <li> Desde la base logística, pasando por la gestión de las devoluciones, entendemos la verificación de las mercancías devueltas y someterlas a un control de calidad. </li> <li> A petición del cliente, podemos llevar a cabo la logística de recuperación (por ejemplo, reparaciones, inspecciones de garantía, desinfección y planchado) </li> </ul>',
        ro: '<ul> <li>Ne ocupăm de comenzile de retur pentru comerțul electronic, precum și pentru canalul tradițional</li> <li>Pe partea de logistică, prin gestionarea retururilor înțelegem verificarea mărfurilor returnate și supunerea acestora la controlul calității</ li> <li>La cererea clienților, putem realiza logistica returului (de exemplu, reparații, inspecții în garanție, dezinfecție și călcat)</li> </ul>',
      },
    },
  ],
}

export const products = {
  id: 'products',
  title: {
    en: 'Build an advantage in industry, thanks to the Omida e-commerce services',
    es: 'Construya una ventaja en la industria gracias a los servicios de comercio electrónico de Omida',
    ro: 'Construiți un avantaj în industrie, datorită serviciilor de comerț electronic Omida',
  },
  desc: {
    en: 'High-end warehousing, supplier selection and wise management are key aspects of the so-called customer-experience',
    es: 'Almacén de primera clase, selección de proveedores y gestión sabia son los aspectos clave del llamado experiencia del cliente',
    ro: 'Depozitarea de vârf, selecția furnizorilor și managementul înțelept sunt aspecte cheie ale așa-numitei experiențe ale clienților',
  },
  products: [
    {
      image: 'magazyn',
      name: { en: 'warehouse', es: 'depósito', ro: 'magazie', },
      alt: 'Warehouse',
      description: {
        en: '',
        es: '',
        ro: '',
      },
      paragraphs: [
        {
          title: {
            en: 'We have a modern warehouse space at our disposal',
            es: 'Contamos con un moderno espacio de almacén',
            ro: 'Avem la dispoziție un spațiu de depozitare modern',
          },
          text: {
            en: 'Our warehouse meets the requirements of class A warehouses located in central Europe. The e-commerce warehouse has an area of 5.5 thousand square meters and is 12 meters high, making it able to store more than 6,500 pallets already during the first stage of expansion!',
            es: 'Nuestro almacén cumple con los requisitos de los almacenes de clase A ubicados en Europa Central. El almacén de comercio electrónico tiene 5,5 mil. m2 de espacio y 12 metros de altura, gracias a lo cual es capaz de albergar más de 6.500 palets en la primera fase de ampliación!',
            ro: 'Depozitul nostru îndeplinește cerințele depozitelor de clasa A situate în Europa centrală. Depozitul de comerț electronic are o suprafață de 5,5 mii de metri pătrați și are o înălțime de 12 metri, ceea ce îl face capabil să depoziteze peste 6.500 de paleți deja în prima etapă de extindere!',
          },
        },
        {
          title: {
            en: 'Partnership-based approach',
            es: 'Un enfoque de asociación',
            ro: 'Abordare bazată pe parteneriat',
          },
          text: {
            en: 'While observing your stock, we are able to optimize your operations and advise you on how to sell more and better.',
            es: 'Al observar los niveles de stock, podemos optimizar sus operaciones y asesorarlo sobre cómo vender más y mejor.',
            ro: 'În timp ce vă observăm stocul, suntem capabili să vă optimizam operațiunile și să vă sfătuim cum să vindeți mai mult și mai bine.',
          },
        },
        {
          title: {
            en: 'Favourable settlements',
            es: 'Asentamientos favorables',
            ro: 'Așezări favorabile',
          },
          text: {
            en: 'With us, you know right away what will be your costs! We offer a transparent model of settlements - every month you will receive a summary of costs divided into all service categories. You pay only for the area which you are using.',
            es: '¡Con nosotros, sabrá de inmediato en qué costos incurrirá! Ofrecemos un modelo de facturación transparente: recibirá un desglose mensual de los costos de todos los beneficios. Solo pagas por el prestigio que usas.',
            ro: 'Cu noi, știi imediat care vor fi costurile tale! Oferim un model transparent de decontări - lunar vei primi un rezumat al costurilor împărțit pe toate categoriile de servicii. Plătiți doar pentru suprafața pe care o utilizați.',
          },
        },
      ],
      button: {
        en: 'Select the Warehouse service',
        es: 'Seleccione el servicio de Almacén',
        ro: 'Selectați serviciul Depozit',
      },
      link: '/ecommerce/#form',
    },
    {
      image: 'biznes',
      name: { en: 'business', es: 'negocio', ro: 'afaceri', },
      description: {
        en: '',
        es: '',
        ro: '',
      },
      paragraphs: [
        {
          title: {
            en: 'Seamless sales scaling',
            es: 'Escalado de ventas sin esfuerzo',
            ro: 'Scalare fără întreruperi a vânzărilor',
          },
          text: {
            en: 'When your online store grows, we grow with you!',
            es: 'Cuando tu tienda online crece, ¡crecemos contigo!',
            ro: 'Când magazinul tău online crește, noi creștem cu tine!',
          },
        },
        {
          title: {
            en: 'Current insight into warehouse operations',
            es: 'Visión actual de las operaciones de almacén',
            ro: 'Perspectivă actuală asupra operațiunilor din depozit',
          },
          text: {
            en: 'With our developed online tools, you can see what is happening with your products at any time, and control the process of handling returns with the support of our staff.',
            es: 'Gracias a nuestras herramientas en línea patentadas, puede consultar lo que sucede con sus productos en cualquier momento, así como controlar el proceso de manejo de devoluciones con la ayuda de nuestros empleados.',
            ro: 'Cu instrumentele noastre online dezvoltate, puteți vedea ce se întâmplă cu produsele dumneavoastră în orice moment și puteți controla procesul de gestionare a retururilor cu sprijinul personalului nostru.',
          },
        },
        {
          title: {
            en: 'Packing according to your specifications',
            es: 'Embalaje según sus directrices',
            ro: 'Ambalare conform specificațiilor dumneavoastră',
          },
          text: {
            en: 'We put at your disposal a wide range of possibilities for personalizing packaging. This is how our fulfillment for your e-Business works!',
            es: 'Te ofrecemos una amplia gama de opciones de personalización de packaging. ¡Así es como funciona nuestro cumplimiento para su e-business!',
            ro: 'Vă punem la dispoziție o gamă largă de posibilități de personalizare a ambalajelor. Așa funcționează îndeplinirea noastră pentru e-Business!',
          },
        },
      ],
      button: {
        en: 'Select the Administration service',
        es: 'Seleccione el servicio de Administración',
        ro: 'Selectați serviciul Administrare',
      },
      link: '/ecommerce/#form',
    },
    {
      image: 'technologia',
      name: { en: 'technology', es: 'tecnología', ro: 'tehnologie', },
      description: {
        en: '',
        es: '',
        ro: '',
      },
      paragraphs: [
        {
          title: {
            en: 'System integration',
            es: 'Integración de sistema',
            ro: 'Integrarea sistemului',
          },
          text: {
            en: 'We offer ready integrations with all major online sales platforms, which guarantees fast and smooth implementation',
            es: 'Tenemos integraciones listas con las principales plataformas de ventas en línea, lo que garantiza una implementación rápida y sin problemas',
            ro: 'Oferim integrări gata cu toate platformele majore de vânzări online, ceea ce garantează o implementare rapidă și fără probleme',
          },
        },
        {
          title: {
            en: 'VMI System ',
            es: 'Sistema VMI',
            ro: 'Sistemul VMI',
          },
          text: {
            en: 'System designed to improve the distribution of goods by increasing the responsibilities of the suppliers.',
            es: 'Un sistema para mejorar la distribución de productos aumentando las obligaciones de los proveedores.',
            ro: 'Sistem conceput pentru a îmbunătăți distribuția mărfurilor prin creșterea responsabilităților furnizorilor.',
          },
        },
        {
          title: { en: 'e-WMS', es: 'e-WMS', ro: 'e-WMS', },
          text: {
            en: 'The system is tailored to optimize space and efficiently manage orders straight from your online store.',
            es: 'El sistema está adaptado a la optimización del espacio y la gestión eficiente de pedidos directamente desde tu tienda online.',
            ro: 'Sistemul este adaptat pentru a optimiza spațiul și a gestiona eficient comenzile direct din magazinul dvs. online.',
          },
        },
      ],
      button: {
        en: 'Select the Technology service',
        es: 'Seleccione el servicio de Tecnología',
        ro: 'Selectați serviciul Tehnologie',
      },
      link: '/ecommerce/#form',
    },
  ],
}

export const table = {
  id: 'table',
  title: {
    en: 'Select an offer dedicated for your e-Business',
    es: 'Elija una oferta específica para su comercio electrónico',
    ro: 'Selectează o ofertă dedicată e-Business-ului tău',
  },
  desc: {
    en: 'Based on our experience we have prepared several packages that will enable handling your e-commerce at a level expected by your customers. Each of the packages can be freely expanded',
    es: 'Basados ​​en nuestra experiencia, hemos preparado varios paquetes que le permitirán atender su comercio electrónico al nivel que sus clientes esperan. Cada uno de los paquetes se puede expandir libremente.',
    ro: 'Pe baza experienței noastre, am pregătit mai multe pachete care vă vor permite gestionarea comerțului dvs. electronic la nivelul așteptat de clienți. Fiecare dintre pachete poate fi extins liber',
  },

  products: [
    {
      name: 'magazyn',
      features: [
        {
          title: {
            en: 'Receipt and verification of goods from our customers contractors or manufacturing plants',
            es: 'Recepción y verificación de mercancías de plantas productivas o contratistas de nuestros clientes',
            ro: 'Recepția și verificarea mărfurilor de la clienții noștri contractori sau fabrici de producție',
          },
          desc: [
            {
              inStandardOffer: '',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                en: 'Unloading and reception of uniform pallets',
                es: 'Descarga y aceptación de palets homogéneos',
                ro: 'Descărcarea și recepția paleților uniformi',
              },
              sub: [
                {
                  title: {
                    en: 'Unloading of a uniform pallet including checking the qualitative and visual protection of the pallet',
                    es: 'Descarga de un palé homogéneo, incluida la comprobación visual y cualitativa de la protección del palé',
                    ro: 'Descărcarea unui palet uniform inclusiv verificarea protecției calitative și vizuale a paletului',
                  },
                },
                {
                  title: {
                    en: 'Placing the pallet in the destination indicated by the warehouse management system (WMS).',
                    es: 'Devolución del palet al destino indicado por el sistema WMS',
                    ro: 'Amplasarea paletului în destinația indicată de sistemul de management al depozitului (WMS).',
                  },
                },
              ],
            },
            {
              inStandardOffer: '',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                en: 'Unloading and de-stacking of multiple pallets',
                es: 'Descarga y desmontaje de palets multigrano',
                ro: 'Descărcarea și dezasamblarea paleților multipli',
              },
              sub: [
                {
                  title: {
                    en: 'Unloading the mix pallet, visually inspecting and decomposing the pallet into individual stock keeping unit (SKU), depositing the SKU to the destination indicated by the WMS',
                    es: 'Descarga del palet mixto, inspección visual y desmontaje del palet para SKU individuales, colocando el SKU en el destino indicado por el sistema WMS',
                    ro: 'Descărcarea paletului de amestec, inspectarea vizuală și descompunerea paletului în unitate individuală de stocare (SKU), depunerea SKU la destinația indicată de WMS',
                  },
                },
              ],
            },
            {
              inStandardOffer: '',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                en: 'Unloading and palletizing of containers',
                es: 'Descarga y paletizado de contenedores',
                ro: 'Descărcarea și paletizarea containerelor',
              },
              sub: [
                {
                  title: {
                    en: 'Opening of the container and registration of the seal number',
                    es: 'Apertura de contenedores y registro del número de precinto',
                    ro: 'Deschiderea containerului și înregistrarea numărului sigiliului',
                  },
                },
                {
                  title: {
                    en: 'Palletizing goods in accordance with the Customers instructions',
                    es: 'Paletización de mercancías de acuerdo con las instrucciones del cliente',
                    ro: 'Paletizarea mărfurilor în conformitate cu instrucțiunile clienților',
                  },
                },
                {
                  title: {
                    en: 'Unloading cartons, opening the carton and checking each piece in terms of quantity and quality',
                    es: 'Descargar las cajas de cartón, abrir la caja de cartón y verificar la calidad y la cantidad de cada artículo',
                    ro: 'Descărcarea cutiilor, deschiderea cutiei și verificarea fiecărei piese din punct de vedere cantitativ și calitativ',
                  },
                },
                {
                  title: {
                    en: 'Placing the items or cartons back in the storage location indicated by the WMS',
                    es: 'Llevar piezas o cartones a la ubicación del almacén indicado por el sistema WMS',
                    ro: 'Plasarea articolelor sau a cutiilor înapoi în locul de depozitare indicat de WMS',
                  },
                },
              ],
            },

            {
              inStandardOffer: 'hidden',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                en: 'Qualitative and quantitative supply verification',
                es: 'Verificación cualitativa y cuantitativa de las entregas',
                ro: 'Verificarea calitativă și cantitativă a aprovizionării',
              },
              sub: [
                {
                  title: {
                    en: 'Reporting of different pallets',
                    es: 'Informes de varios transportistas de paletas',
                    ro: 'Raportarea diferitelor paleți',
                  },
                },
                {
                  title: {
                    en: 'Reporting aberration in the amounts of goods and their quality',
                    es: 'Reporte de aberraciones en las cantidades de bienes y su calidad',
                    ro: 'Raportarea aberațiilor în cantitățile de mărfuri și calitatea acestora',
                  },
                },
                {
                  title: {
                    en: 'Photos from the receipt of goods',
                    es: 'Fotografías de la entrada de mercancías.',
                    ro: 'Fotografii de la recepția mărfurilor',
                  },
                },
              ],
            },
            {
              inStandardOffer: 'hidden',
              inBusinessOffer: 'hidden',
              inPremiumOffer: '',
              title: {
                en: 'Loading, unloading, transfers of large packages (over 50kg)',
                es: 'Carga, descarga, transporte de paquetes grandes (más de 50 kg)',
                ro: 'Încărcarea, descărcarea, transferul pachetelor mari (peste 50 kg)',
              },
              sub: [],
            },
          ],
          Standard: { en: 'Standard', es: 'Standard', ro: 'Standard', },
          Business: { en: 'Business', es: 'Business', ro: 'Business', },
          Premium: { en: 'Premium', es: 'Premium', ro: 'Premium' },
        },
        {
          title: {
            en: 'Warehousing',
            es: 'Almacenamiento',
            ro: 'Magazinaj',
          },
          desc: [
            {
              inStandardOffer: '',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                en: 'Warehousing in dedicated shelf locations',
                es: 'Almacenamiento en ubicaciones de estantes dedicadas',
                ro: 'Depozitare în locații de rafturi dedicate',
              },
              sub: [
                {
                  title: {
                    en: 'High storage class A warehouse, Sprinkler installations',
                    es: 'Almacén de gran altura Clase A, Instalaciones de rociadores',
                    ro: 'Depozit clasa A ridicat, Instalații sprinklere',
                  },
                },
                {
                  title: {
                    en: 'Cleanliness maintenance and protection programme (internal and external control)',
                    es: 'Programa de limpieza y seguridad (control externo e interno)',
                    ro: 'Program de întreținere și protecție a curățeniei (control intern și extern)',
                  },
                },
                {
                  title: {
                    en: 'High storage racks for pallets weighing up to 800 kg and shelves. Storage height - up to 2m.',
                    es: 'Estanterías altas para palets de hasta 800 kg y estantes para estanterías. Altura de almacenamiento hasta 2m.',
                    ro: 'Rafturi înalte de depozitare pentru paleți cu o greutate de până la 800 kg și rafturi. Înălțimea de depozitare - până la 2 m.',
                  },
                },
              ],
            },

            {
              inStandardOffer: '',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                en: 'Year-round guaranteed temperaturesabove 15 degreesCelsius. Humidity 50-70%',
                es: 'Garantía de temperatura durante todo el año por encima de 15 ° C. Humedad 50-70%',
                ro: 'Temperaturi garantate pe tot parcursul anului peste 15 grade Celsius. Umiditate 50-70%',
              },
              sub: [],
            },
            {
              inStandardOffer: '',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                en: 'Security system including 24-hour monitoring',
                es: 'Sistema de seguridad que incluye monitoreo las 24 horas',
                ro: 'Sistem de securitate inclusiv monitorizare 24 de ore din 24',
              },
              sub: [
                {
                  title: {
                    en: 'CCTV monitoring with up to 30-days long availability of stored data and 24-hours supervision of a licensed security company',
                    es: 'Monitoreo con disponibilidad de registro de datos por un período de hasta 30 días y supervisión las 24 horas de una empresa de seguridad autorizada',
                    ro: 'Monitorizare CCTV cu disponibilitate de până la 30 de zile a datelor stocate și supraveghere 24 de ore pe zi a unei companii de securitate licențiate',
                  },
                },
              ],
            },
            {
              inStandardOffer: '',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                en: 'Different methods of goods management',
                es: 'Diversos métodos de gestión de las mercancías.',
                ro: 'Diferite metode de gestionare a mărfurilor',
              },
              sub: [
                {
                  title: {
                    en: 'FEFO for products with expiration dates',
                    es: 'FEFO para productos con fecha de caducidad',
                    ro: 'FEFO pentru produsele cu termen de expirare',
                  },
                },
                {
                  title: {
                    en: 'FIFO for other products without expiration date',
                    es: 'FIFO para otros productos sin fecha de caducidad',
                    ro: 'FIFO pentru alte produse fără data de expirare',
                  },
                },
              ],
            },
            {
              inStandardOffer: '',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                en: 'E-WMS -Warehouse Management System',
                es: 'E-WMS - sistema de gestión de almacenes',
                ro: 'E-WMS -Sistem de management al depozitului',
              },
              sub: [
                {
                  title: {
                    en: 'It is a computer software for comprehensive traffic management of products in warehouses.The data iscollected by means of barcodes with the use of scanners, readers and data collectors.',
                    es: 'Es un software informático para la gestión integral del movimiento de productos en almacenes. Los datos se recopilan mediante códigos de barras con el uso de escáneres, lectores y recopiladores de datos.',
                    ro: 'Este un software de calculator pentru gestionarea cuprinzătoare a traficului de produse din depozite. Datele sunt colectate prin intermediul codurilor de bare cu ajutorul scanerelor, cititoarelor și colectoarelor de date.',
                  },
                },
              ],
            },
            {
              inStandardOffer: '',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                en: 'Flat surface bulk type ',
                es: 'Superficie plana a granel',
                ro: 'Tip suprafață plană în vrac',
              },
              sub: [
                {
                  title: {
                    en: 'Block and surface storage',
                    es: 'Almacenamiento en bloque y en superficie',
                    ro: 'Depozitare bloc și suprafață',
                  },
                },
              ],
            },
            {
              inStandardOffer: 'hidden',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                en: 'Dedicated area with access control for high-value product',
                es: 'Un área dedicada con control de acceso para productos de alto valor',
                ro: 'Zona dedicată cu control acces pentru produse de mare valoare',
              },
              sub: [],
            },
          ],
          Standard: { en: 'Standard', es: 'Standard', ro: 'Standard', },
          Business: { en: 'Business', es: 'Business', ro: 'Business', },
          Premium: { en: 'Premium', es: 'Premium', ro: 'Premium' },
        },
      ],
    },
    {
      name: 'biznes',
      features: [
        {
          title: {
            en: 'B2B and B2C order shipping',
            es: 'Envío de pedidos en el modelo B2C & B2B',
            ro: 'Livrare comenzi B2B și B2C',
          },
          desc: [
            {
              inStandardOffer: '',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                en: 'Single picking',
                es: 'recolección única',
                ro: 'Culegere unică',
              },
              sub: [],
            },
            {
              inStandardOffer: 'hidden',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                en: 'Additional check for correctness of picking inthe packing buffer',
                es: 'Comprobación adicional de la corrección del picking en el tampón de embalaje',
                ro: 'Verificare suplimentară pentru corectitudinea ridicării în tamponul de ambalare',
              },
              sub: [
                {
                  title: {
                    en: 'Photos of package contents stored in OMIDA system',
                    es: 'Fotos del contenido del paquete almacenadas en el sistema OMIDA',
                    ro: 'Fotografii cu conținutul pachetului stocat în sistemul OMIDA',
                  },
                },
              ],
            },
            {
              inStandardOffer: 'hidden',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                en: 'E-picking - comprehensive packaging service',
                es: 'E-picking - servicio integral de embalaje',
                ro: 'E-picking - serviciu complet de ambalare',
              },
              sub: [
                {
                  title: {
                    en: 'Preparation of shipment documents, printing and adding invoices to shipments, customization of packaging (customer logo, tape with a logo, eco-friendly packing fillers)',
                    es: 'Elaboración de documentos de envío, impresión y adición de facturas a los envíos, personalización de embalajes (logotipo del cliente, cinta con logotipo, rellenos ecológicos)',
                    ro: 'Pregătirea documentelor de expediere, tipărirea și adăugarea facturilor la expedieri, personalizarea ambalajelor (sigla clientului, bandă cu logo, materiale de umplutură ecologice)',
                  },
                },
              ],
            },
            {
              inStandardOffer: 'hidden',
              inBusinessOffer: 'hidden',
              inPremiumOffer: '',
              title: {
                en: 'E-fulfilment – logistics process from A to Z',
                es: 'E-fulfilment – proceso logístico de la A a la Z',
                ro: 'E-fulfilment – proces logistic de la A la Z',
              },
              sub: [
                {
                  title: {
                    en: 'Implementation and operation of warehouses manufacturers for outgoing, regional/national distribution centres with dedicated or shared space',
                    es: 'Implementación y servicio de almacén de fabricantes para productos salientes de centros de distribución regionales/nacionales con espacio dedicado o compartido',
                    ro: 'Implementarea si operarea producătorilor de depozite pentru centre de distribuție de ieșire, regionale/naționale cu spațiu dedicat sau comun',
                  },
                },
                {
                  title: {
                    en: 'Supply of semi-finished or finished products to distribution warehouses',
                    es: 'Entrega de productos semielaborados o terminados a almacenes de distribución',
                    ro: 'Furnizare de semifabricate sau produse finite către depozitele de distribuție',
                  },
                },
                {
                  title: {
                    en: 'Communication with the client through the full support of electronic tools',
                    es: 'Comunicación con el cliente a través de soporte completo de herramientas electrónicas',
                    ro: 'Comunicarea cu clientul prin suportul complet al instrumentelor electronice',
                  },
                },
                {
                  title: {
                    en: 'Management of orders, PO-to-SO purchase and sales processes',
                    es: 'Gestión de pedidos, gestión de procesos de ventas PO / SO',
                    ro: 'Gestionarea comenzilor, proceselor de cumpărare și vânzare PO-to-SO',
                  },
                },
                {
                  title: {
                    en: 'Coordination of deliveries to retail, trade channels and final customers',
                    es: 'Coordinación de entregas al comercio minorista, canales comerciales y destinatarios finales',
                    ro: 'Coordonarea livrărilor către retail, canale comerciale si clienți finali',
                  },
                },
                {
                  title: {
                    en: 'Stock management, visibility, tracking and planning',
                    es: 'Gestión de inventario, visibilidad, seguimiento y planificación',
                    ro: 'Gestionarea stocurilor, vizibilitate, urmărire și planificare',
                  },
                },
              ],
            },
          ],
          Standard: { en: 'Standard', es: 'Standard', ro: 'Standard', },
          Business: { en: 'Business', es: 'Business', ro: 'Business', },
          Premium: { en: 'Premium', es: 'Premium', ro: 'Premium' },
        },
        {
          title: {
            en: 'Handling returns and complaints',
            es: 'Gestión de devoluciones y reclamaciones',
            ro: 'Gestionarea retururilor și reclamațiilor',
          },
          desc: [
            {
              inStandardOffer: '',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                en: 'E–omnichannel',
                es: 'E–omnichannel',
                ro: '',
              },
              sub: [
                {
                  title: {
                    en: 'Handling orders and returns for e-commerce as well as for the traditional channel',
                    es: 'Gestión de pedidos y devoluciones para comercio electrónico y para el canal tradicional',
                    ro: 'Gestionarea comenzilor și retururilor pentru comerțul electronic, precum și pentru canalul tradițional',
                  },
                },
              ],
            },
            {
              inStandardOffer: 'hidden',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                en: 'E-Experience',
                es: 'E-Experience',
                ro: 'E-Experience',
              },
              sub: [
                {
                  title: {
                    en: 'In terms of logistics, by returns handling we mean verification of returned goods and subjecting them to quality control',
                    es: 'Desde el punto de vista de la base logística, por tramitación de devoluciones entendemos la verificación de la mercancía devuelta y su sometimiento a control de calidad',
                    ro: 'Din punct de vedere logistic, prin manipularea retururilor înțelegem verificarea mărfurilor returnate și supunerea acestora la controlul calității',
                  },
                },
                {
                  title: {
                    en: 'Tailor-made solutions within the service of returns handling depending on your needs',
                    es: 'Soluciones preparadas individualmente como parte del manejo de devoluciones dependiendo de las necesidades',
                    ro: 'Soluții personalizate în cadrul serviciului de gestionare a retururilor în funcție de nevoile dumneavoastră',
                  },
                },
              ],
            },
            {
              inStandardOffer: 'hidden',
              inBusinessOffer: 'hidden',
              inPremiumOffer: '',
              title: {
                en: 'Recycling/Disposal',
                es: 'Reciclaje/Utilización',
                ro: 'Reciclare/Aruncare',
              },
              sub: [],
            },

            {
              inStandardOffer: 'hidden',
              inBusinessOffer: 'hidden',
              inPremiumOffer: '',
              title: {
                en: 'Recovery Logistics (e.g.repair, warranty controls)',
                es: 'Logística de recuperación (por ejemplo, reparaciones, inspecciones de garantía)',
                ro: 'Logistica de recuperare (de exemplu, reparații, controale în garanție)',
              },
              sub: [],
            },
          ],
          Standard: { en: 'Standard', es: 'Standard', ro: 'Standard', },
          Business: { en: 'Business', es: 'Business', ro: 'Business', },
          Premium: { en: 'Premium', es: 'Premium', ro: 'Premium' },
        },
        {
          title: {
            en: 'Courier services',
            es: 'Servicios de mensajería',
            ro: 'Servicii de curierat',
          },
          desc: [
            {
              inStandardOffer: '',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                en: 'We offer a full package of courier services - national shipping by DPD, FedEx, TNT, DHL, UPS, parcel machines and pallet transport',
                es: 'Ofrecemos un paquete completo de servicios de mensajería: envío nacional por DPD, FedEx, TNT, DHL, UPS, máquinas de paquetería y transporte de palets.',
                ro: 'Oferim un pachet complet de servicii de curierat - expediere națională prin DPD, FedEx, TNT, DHL, UPS, mașini de colete și transport paleți',
              },
              sub: [
                {
                  title: {
                    en: 'We ensure customer comfort through the possibility of choosing: the method, place and time of delivery optimal for the recipient',
                    es: 'Proporcionamos la comodidad del cliente gracias a la posibilidad de elegir el lugar y la hora de entrega óptimos para el destinatario',
                    ro: 'Asigurăm confortul clienților prin posibilitatea de a alege: metoda, locul și timpul de livrare optim pentru destinatar',
                  },
                },
                {
                  title: {
                    en: 'We guarantee the proper selection of packaging dimensionsfrom 3 types suitable for parcel machines',
                    es: 'Selección adecuada de embalaje para 3 tipos de tamaños de máquinas de paquetería',
                    ro: 'Garantăm selecția corectă a dimensiunilor ambalajului din 3 tipuri potrivite pentru mașinile de coletat',
                  },
                },
                {
                  title: {
                    en: 'Handling returns',
                    es: 'Gestión de devoluciones',
                    ro: 'Gestionarea retururilor',
                  },
                },
                {
                  title: {
                    en: 'The possibility of forwarding the shipment to the pick-up point',
                    es: 'Posibilidad de redirigir el paquete a un punto de recogida',
                    ro: 'Posibilitatea de a trimite expedierea la punctul de preluare',
                  },
                },
                {
                  title: {
                    en: 'Full Track and Trace',
                    es: 'Truck and Trace completos',
                    ro: 'Track and Trace complet',
                  },
                },
              ],
            },
          ],
          Standard: { en: 'Standard', es: 'Standard', ro: 'Standard', },
          Business: { en: 'Business', es: 'Business', ro: 'Business', },
          Premium: { en: 'Premium', es: 'Premium', ro: 'Premium' },
        },
        {
          title: {
            en: 'E-customs - Customs service includes:',
            es: 'E-customs - Los servicios de aduanas incluyen:',
            ro: 'E-vamă - Serviciul vamal include:',
          },
          desc: [
            {
              inStandardOffer: 'hidden',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                en: 'Preparation of customs documentation, calculation of customs duties and taxes, affixing and removal of customs seals, and thus allowing the goods circulation within our country',
                es: 'Elaboración de la documentación aduanera, cálculo de los derechos aduaneros y fiscales, colocación y retirada de los precintos aduaneros y, por tanto, la comercialización de las mercancías en nuestro país',
                ro: 'Intocmirea documentației vamale, calculul taxelor si taxelor vamale, aplicarea si îndepărtarea sigiliilor vamale, și astfel permiterea circulației mărfurilor in tara noastră.',
              },
              sub: [],
            },
            {
              inStandardOffer: 'hidden',
              inBusinessOffer: 'hidden',
              inPremiumOffer: '',
              title: {
                en: 'HUZAR application for supervision of the processes',
                es: 'Procesos supervisados ​​por la aplicación HUZAR',
                ro: 'Aplicația HUZAR pentru supravegherea proceselor',
              },
              sub: [],
            },
          ],
          Standard: { en: 'Standard', es: 'Standard', ro: 'Standard', },
          Business: { en: 'Business', es: 'Business', ro: 'Business', },
          Premium: { en: 'Premium', es: 'Premium', ro: 'Premium' },
        },
      ],
    },
    {
      name: 'technologia',
      features: [
        {
          title: {
            en: 'Value-Added Services',
            es: 'Servicios de Valor Agregado',
            ro: 'Servicii cu valoare adăugată',
          },
          desc: [
            {
              inStandardOffer: '',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                en: 'IT integration',
                es: 'integración de TI',
                ro: 'integrarea IT',
              },
              sub: [],
            },
            {
              inStandardOffer: 'hidden',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                en: 'Co-packing services ',
                es: 'Servicios de co-empaque ',
                ro: 'Servicii de co-ambalare ',
              },
              sub: [
                {
                  title: {
                    en: 'Assembling sets (blistering, schelling) ',
                    es: 'Juegos de montaje (ampollamiento, descascarillado)',
                    ro: 'Seturi de asamblare (blistering, schelling) ',
                  },
                },
                {
                  title: {
                    en: 'Personalization (e.g. laser engraving)',
                    es: 'Personalización (por ejemplo, grabado láser)',
                    ro: 'Personalizare (de exemplu, gravare cu laser)',
                  },
                },
                {
                  title: {
                    en: 'Completion of stands ',
                    es: 'Reposición de stand',
                    ro: 'Finalizarea standurilor ',
                  },
                },
              ],
            },
            {
              inStandardOffer: 'hidden',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                en: 'E-insert',
                es: 'E-insert',
                ro: 'E-insert',
              },
              sub: [
                {
                  title: {
                    en: 'Adding flyers, samples, gadgets, eco-friendly packaging fillers and scent',
                    es: 'Incluyendo folletos, muestras, artilugios, rellenos ecológicos y perfumados',
                    ro: 'Adăugarea de fluturași, mostre, gadgeturi, materiale de umplutură pentru ambalaje ecologice și parfum',
                  },
                },
              ],
            },

            {
              inStandardOffer: 'hidden',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                en: 'Integration with e-commerce platforms',
                es: 'Integración con plataformas de comercio electrónico',
                ro: 'Integrare cu platformele de comerț electronic',
              },
              sub: [],
            },
            {
              inStandardOffer: 'hidden',
              inBusinessOffer: 'hidden',
              inPremiumOffer: '',
              title: {
                en: 'Integration with the shop under a single dashboard',
                es: 'Integración con la tienda bajo un tablero',
                ro: 'Integrare cu magazinul sub un singur tablou de bord”',
              },
              sub: [],
            },
          ],
          Standard: { en: 'Standard', es: 'Standard', ro: 'Standard', },
          Business: { en: 'Business', es: 'Business', ro: 'Business', },
          Premium: { en: 'Premium', es: 'Premium', ro: 'Premium' },
        },
        {
          title: {
            en: 'Extra operating elements',
            es: 'Elementos operativos adicionales',
            ro: 'Elemente de operare suplimentare',
          },
          desc: [
            {
              inStandardOffer: '',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                en: 'The possibility of fiscalization of products in stock (customers fiscal cash register, receipt printout, VAT invoice printout)',
                es: 'Posibilidad de fiscalizar productos en el almacén (caja registradora del cliente, impresión de un recibo, impresión de una factura con IVA)',
                ro: 'Posibilitatea de fiscalizare a produselor din stoc (casa de marcat fiscală clienți, tipărire chitanță, tipărire facturi cu TVA)',
              },
              sub: [],
            },
            {
              inStandardOffer: 'hidden',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                en: 'Full pallet cross-docking services',
                es: 'Servicios de cross-docking para palets completos',
                ro: 'Servicii complete de cross-docking pentru paleți',
              },
              sub: [
                {
                  title: {
                    en: 'ECR (Efficient Customer Response) -full pallets of homogenous product are routed directly to the store',
                    es: 'ECR (Servicio al Cliente Efectivo) - las paletas completas de producto homogéneo se dirigen directamente a la tienda',
                    ro: 'ECR (Efficient Customer Response) -paleții plini de produs omogen sunt direcționați direct la magazin',
                  },
                },
              ],
            },

            {
              inStandardOffer: 'hidden',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                en: 'Dedicated customer service',
                es: 'Servicio al cliente dedicado',
                ro: 'Serviciu dedicat clienților',
              },
              sub: [],
            },
            {
              inStandardOffer: 'hidden',
              inBusinessOffer: 'hidden',
              inPremiumOffer: '',
              title: {
                en: 'Merge in transit',
                es: 'Merge in transit',
                ro: 'Fuzionați în tranzit',
              },
              sub: [
                {
                  title: {
                    en: 'Combination of goods from the warehouse with external supply and accumulation for one shipment',
                    es: 'Total de mercancías del stock con entrega externa y acumulación a un envío',
                    ro: 'Combinație de mărfuri din depozit cu aprovizionare externă și acumulare pentru o singură expediție',
                  },
                },
              ],
            },
          ],
          Standard: { en: 'Standard', es: 'Standard', ro: 'Standard', },
          Business: { en: 'Business', es: 'Business', ro: 'Business', },
          Premium: { en: 'Premium', es: 'Premium', ro: 'Premium' },
        },
      ],
    },
  ],
}

export const plans = {
  id: 'plans',
  title: {
    en: 'Find out which package is the most suitable for your e-Business',
    es: 'Verifique qué paquete será perfecto para su negocio electrónico',
    ro: 'Aflați care pachet este cel mai potrivit pentru e-Business-ul dvs',
  },
  desc: {
    en: 'We have prepared three packages, thanks to which we are able to handle both small, medium and very large companies. Each of the packages can be individually customized',
    es: 'Hemos preparado tres paquetes, gracias a los cuales podemos dar servicio a pequeñas, medianas y muy grandes empresas. Cada uno de los paquetes se puede ajustar individualmente ',
    ro: 'Am pregătit trei pachete, datorită cărora ne putem ocupa atât de companii mici, medii și foarte mari. Fiecare dintre pachete poate fi personalizat individual',
  },
  plans: [
    {
      name: 'Standard',
      color: '#ec4e37',
      text: {
        en: 'We offer support and professional technical solutions in logistics for e-commerce. Standard Package is designed for small and medium entrepreneurs, whose e-businesses are growing rapidly, and therefore they are in need of storage space. Standard Package includes mostly basic fulfilment services, as well as operation of e-VMS system, which enables planning and management of product movement in the warehouse.',
        es: 'Brindamos soporte y garantizamos soluciones técnicas profesionales relacionadas con la logística para el comercio electrónico. El Paquete Estándar fue creado para pequeños y medianos empresarios cuyo comercio electrónico se está desarrollando de manera dinámica y, por lo tanto, necesitan espacio de almacenamiento. El paquete estándar incluye principalmente servicios de cumplimiento básicos, así como soporte de e-WMS, que permite la planificación y gestión del movimiento de productos en el almacén.',
        ro: '',
      },
    },
    {
      name: 'Business',
      color: '#486CB5',
      text: {
        en: 'Business package is designed for shops which appreciate unique and professional service. Business package includes complete e-fulfilment - storage, delivery coordination, communication with the customer, stock management, as well as handling returns and complaints. This means that with our support you will be able to save time and reduce costs, as we will handle the entire logistics process from A to Z.',
        es: 'El Business Package está destinado a tiendas que valoran un servicio excepcional y profesional. Incluye e-fulfillment completo: almacenamiento, coordinación de entregas, comunicación con el cliente, gestión de inventario, pero también gestión de devoluciones y reclamaciones. Esto quiere decir que con nuestra ayuda ahorrarás tiempo y reducirás costes, porque nos encargaremos de todo el proceso logístico de la A a la Z.',
        ro: '',
      },
    },
    {
      name: 'Premium',
      color: '#6BB53A',
      text: {
        en: 'Premium package is designed for the most demanding customers. Within the Premium package we not only take over the whole logistics process, but also give you plenty of opportunities to meet the expectations and needs of your customers. We have a whole range of value-added services, thanks to which we can personalize each parcel by e.g. attaching inserts (leaflets, samples, eco-friendly packing fillers, tapes with logo). In addition, within this package we offer integration with the shop under a single dashboard and integration with e-commerce platforms.',
        es: 'El paquete premium fue creado para los clientes más exigentes. En el paquete Premium, no solo nos hacemos cargo de todo el proceso logístico, sino que también le brindamos muchas oportunidades para satisfacer las expectativas y necesidades de sus clientes. Disponemos de toda una gama de servicios de valor añadido, gracias a los cuales podemos personalizar cada paquete, incl. mediante la fijación de insertos (folletos, muestras, rellenos ecológicos, cinta con el logotipo de la empresa). Además, en este paquete ofrecemos integración con la tienda en un solo tablero e integración con plataformas de comercio electrónico. ',
        ro: '',
      },
    },
  ],

  button: {
    text: {
      en: 'Compare the Plans',
      es: 'Comparar planes',
      ro: '',
    },
    link: '/ecommerce/#table',
  },
}

export const form = {
  id: 'form',
  title: {
    en: 'Lets choose together an offer tailored to your e-Business!',
    es: '¡Elijamos juntos una oferta adaptada a su e-Business!',
    ro: '',
  },
  desc: {
    en: 'Are you interested? Send us a message! We are happy to answer any questions you may have',
    es: '¿Interesado? ¡Mandanos un mensaje! Estaremos encantados de responder a todas sus preguntas',
    ro: '',
  },
  fields: {
    email: {
      label: { en: 'Email', es: 'Email', ro: 'Email' },
      placeholder: { en: '', es: '', ro: '' },
      type: 'text',
      isRequired: true,
    },
    name: {
      label: { en: 'Contact person', es: 'Persona de contacto', ro: 'Persoana de contact' },
      placeholder: { en: '', es: '', ro: '' },
      type: 'text',
      isRequired: true,
    },
    plan: {
      label: { en: 'Plan', es: 'Plan', ro: 'Plan' },
      placeholder: { en: '', es: '', ro: '', },
      type: 'select',
      isRequired: true,
    },
    message: {
      label: { en: 'Message', es: 'Mensaje', ro: 'Mesaj' },
      placeholder: { en: '', es: '', ro: '' },
      type: 'message',
      isRequired: true,
    },
  },
  agreements: [
    {
      en: 'I agree to receive by e-mail commercial information coming from Omida Logistics Sp. z o.o. within the meaning of the Act of 18 July 2002 on electronic services',
      es: 'Acepto recibir información comercial por medios electrónicos en el sentido de la Ley de 18 de julio de 2002 sobre prestación de servicios electrónicos con contenidos de Omida Logistics Sp. z o.o.',
      ro: 'Sunt de acord să primesc prin e-mail informații comerciale provenite de la Omida Logistics Sp. z o.o. în sensul Legii din 18 iulie 2002 privind serviciile electronice',
    },
    {
      en: 'I hereby consent to the use by Omida Logistics Sp. z o.o. of telecommunications terminal equipment and automated calling systems to present commercial information within the meaning of the Act of 16 July 2014. -Telecommunications Law',
      es: 'Consiento el uso por parte de Omida Logistics Sp. z o.o. equipos terminales de telecomunicaciones y sistemas automáticos de llamada para presentar información comercial en el sentido de lo dispuesto en la Ley de 16 de julio de 2014 - Ley de Telecomunicaciones',
      ro: 'Sunt de acord cu utilizarea de către Omida Logistics Sp. z o.o. a echipamentelor terminale de telecomunicații și a sistemelor automate de apelare pentru a prezenta informații comerciale în sensul Legii din 16 iulie 2014. -Legea telecomunicațiilor',
    },
  ],
  administrating: {
    en: 'The administrator of your personal data will be Omida Logistics Sp. z o.o.(hereinafter: Omida). You can contact us as follows: by letter to: Aleja Grunwaldzka 472C, 80-309 Gdańsk, by e-mail:',
    es: 'El administrador de sus datos personales será Omida Logistics Sp. z o.o. (en adelante: Omida). Puede ponerse en contacto con nosotros de la siguiente manera: por carta a la siguiente dirección: Aleja Grunwaldzka 472C, 80-309 Gdańsk, por correo electrónico:',
    ro: 'Administratorul datelor dumneavoastră personale va fi Omida Logistics Sp. z o.o. (în continuare: Omida). Ne puteți contacta după cum urmează: prin scrisoare către: Aleja Grunwaldzka 472C, 80-309 Gdańsk, prin e-mail:',
  },
  administratingSecond: {
    en: 'or by phone: +48 58 741 88 14. Personal data will be processed in order to prepare offers on Omida products and services. Details about the processing of your personal data are available',
    es: 'o por teléfono: +48 58 741 88 14. Procesaremos datos personales para preparar ofertas de productos y servicios de Omida. Puede encontrar información detallada sobre el procesamiento de datos personales en ',
    ro: 'sau la telefon: +48 58 741 88 14. Datele personale vor fi prelucrate în vederea pregătirii ofertelor pentru produsele și serviciile Omida. Detalii despre prelucrarea datelor dumneavoastră cu caracter personal sunt disponibile',
  },
  administratingLinkText: {
    en: 'here',
    es: 'aquí',
    ro: 'aici',
  },
  dictonary: {
    success: {
      title: { en: 'Thank you!', es: '¡Gracias!', ro: '¡Gracias!' },
      text: {
        en: 'Your message has been sent. We will reply as soon as possible. We also sent you the confirmation to provided email address',
        es: 'Tu mensaje ha sido enviado. Le contestaremos lo antes posible. También enviamos una confirmación del mensaje a la dirección proporcionada',
        ro: 'Mesajul tau a fost trimis. Vă vom răspunde cât mai curând posibil. De asemenea, v-am trimis confirmarea la adresa de e-mail furnizată',
      },
    },
    failure: {
      title: { en: 'Failure!', es: '¡Falla!', ro: 'Eșec!' },
      text: {
        en: 'It looks like your message has not been sent. Contact us by e - mail or by phone',
        es: 'Parece que su mensaje no ha sido enviado. Contáctenos por correo electrónico o por teléfono',
        ro: 'Se pare că mesajul tău nu a fost trimis. Contactați-ne prin e-mail sau telefonic',
      },
    },
    send: {
      en: 'Send',
      es: 'Enviar',
      ro: 'Trimiteți',
    },
    fill: {
      en: 'Fill up the form',
      es: 'Rellene el formulario',
      ro: 'Completați formularul',
    },
    fillagreements: {
      en: 'Agree to the terms',
      es: 'Seleccione el consentimiento',
      ro: 'De acord cu termenii',
    },
  },
}

export const buttonBack = {
  text: {
    en: 'Ask for an offer',
    es: 'Pide una oferta',
    ro: 'Cereți o ofertă',
  },
  link: '/ecommerce/#form',
}
